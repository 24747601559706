import React, {useState} from 'react';
import {checkFileErr, getPermissionSites, parseNum} from 'app/helpers';
import {Button, Col, Form, Input, message, Radio, Row, Select, Space, Spin, Switch, Typography} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import type Types from 'MyTypes';
import {create, reset} from './actions';
import {get as getMasters} from '../ProjectProcessMaster/actions';
import {useDispatch, useSelector} from 'react-redux';
import {type UploadFile} from 'app/models/upload-file';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {AttachmentParentType, type ContractorForeman, ModuleName, ModulePermission, OrderType, type PaymentMilestone, type Site, type WorkOrder, type WorkOrderSchedule} from 'app/models';
import {FileUpload} from '../Common/FileUpload';
import {uiPaths} from 'app/constants';
import {DefaultLayout} from '../Layout/DefaultLayout';
import {useHistory, useParams} from 'react-router';
import {ProjectProcessType} from 'app/components/ProjectProcessMaster/project-process-master';
import {getContractors} from '../Common/summary-actions';
import {SiteSelect} from '../Common/SiteSelect';
import {get as getCategories} from '../Category/actions';
import {CategoryType} from '../Category/category';

type ParamType = {
	scheduleId?: string;
};

// eslint-disable-next-line complexity
export const ViewWorkOrderForm: React.FC = () => {
	const {TextArea} = Input;
	const {Option} = Select;

	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams<ParamType>();

	const {loading, dataUpdated, errorMessage} = useSelector((state: Types.RootState) => state.workOrder);
	const {byIds: scheduleByIds} = useSelector((state: Types.RootState) => state.workOrderSchedule);
	const {byIds: masterByIds, allIds: masterAllIds} = useSelector((state: Types.RootState) => state.projectProcessMaster);
	const {contractorOptions} = useSelector((state: Types.RootState) => state.summary);
	const {byModule: permissions, allSites} = useSelector((state: Types.RootState) => state.userPermission);
	const {byIds: categoryByIds, allIds: allCategoryIds} = useSelector((state: Types.RootState) => state.category);

	const scheduleId = parseInt(params?.scheduleId ?? '0', 10);
	const workOrderSchedule: WorkOrderSchedule | undefined = scheduleId ? scheduleByIds[scheduleId] : undefined;
	const projectProcessMaster = workOrderSchedule?.projectProcessMaster ?? undefined;
	const sites: Site[] = getPermissionSites(permissions, ModuleName.WORK_ORDER, ModulePermission.WRITE, allSites);

	const categories = allCategoryIds.map(id => categoryByIds[id]).filter(category => !category.isDeactivated);

	const categoryOptions = categories
		.filter(category => !category.parentId)
		.map(item => ({value: item.id.toString(), label: item.name}));

	const subCategoryOptions = parentId => categories
		.filter(category => category.parentId === parentId)
		.map(item => ({value: item.id.toString(), label: item.name}));

	const [workOrderData, setWorkOrderData] = useState<WorkOrder>({
		id: 0,
		debits: [],
		orderType: OrderType.WORK,
		title: '',
		siteId: workOrderSchedule?.siteId ?? 0,
		contractorId: 0,
		projectProcessMasterId: workOrderSchedule?.projectProcessMasterId ?? undefined,
		contractorVisit: '',
		scopeOfWork: '',
		materialScopeContractorSupContractor: '',
		materialScopeContractorProvPrithu: '',
		itemRateListExtra: '',
		qualityCheck: '',
		schedule: '',
		contactPerson: '',
		generalClauses: '',
		drawingQuantityCalc: '',
		qualityAndPrice: '',
		tenderComparison: '',
		contractorForemen: [],
		paymentMilestones: projectProcessMaster?.milestoneNames ? projectProcessMaster.milestoneNames.split(',')
			.map((name: string, ix: number) => ({name: name.trim(), amount: 0, sequence: ix + 1, isSettlement: false, workOrderId: 0, id: 0})) : [],
		quantityMeasureRule: '',
		ratePerUnit: '',
		billedToClient: false,
		workOrderQuantity: undefined,
		subCategoryId: undefined,
	});
	const [categoryId, setCategoryId] = useState<number>(0);

	const [scopeOfWorkFileList, setScopeOfWorkFileList] = useState<UploadFile[]>([]);
	const [materialScopeContractorSupContractorFileList, setMaterialScopeContractorSupContractorFileList] = useState<UploadFile[]>([]);
	const [materialScopeContractorProvPrithuFileList, setMaterialScopeContractorProvPrithuFileList] = useState<UploadFile[]>([]);
	const [itemRateListExtraFileList, setItemRateListExtraFileList] = useState<UploadFile[]>([]);
	const [qualityCheckFileList, setQualityCheckFileList] = useState<UploadFile[]>([]);
	const [scheduleFileList, setScheduleFileList] = useState<UploadFile[]>([]);
	const [contactPersonFileList, setContactPersonFileList] = useState<UploadFile[]>([]);
	const [generalClausesFileList, setGeneralClausesFileList] = useState<UploadFile[]>([]);
	const [drawingQuantityCalcFileList, setDrawingQuantityCalcFileList] = useState<UploadFile[]>([]);
	const [qualityAndPriceFileList, setQualityAndPriceFileList] = useState<UploadFile[]>([]);
	const [tenderComparisonFileList, setTenderComparisonFileList] = useState<UploadFile[]>([]);
	const [otherFileList, setOtherFileList] = useState<UploadFile[]>([]);

	const [filePrefixKeys, setFilePrefixKeys] = React.useState<Record<string, string>>({});

	React.useEffect(() => {
		dispatch(reset());
		dispatch(getMasters({where: {projectProcessType: ProjectProcessType.ORDER}}));
		dispatch(getContractors());
		dispatch(getCategories({
			where: {
				categoryType: CategoryType.MATERIAL_QUERY,
			},
		}));
	}, []);

	React.useEffect(() => {
		if (dataUpdated) {
			void message.success('Created successfully');
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}
	}, [dataUpdated]);

	React.useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const onContractorForemenAdd = () => {
		const items = [...workOrderData.contractorForemen];
		items.push({name: '', phone: ''} as ContractorForeman);
		setWorkOrderData({...workOrderData, contractorForemen: items});
	};

	const onContractorForemenNameChange = (i: number, name: string) => {
		const items = [...workOrderData.contractorForemen];
		if (items[i]) {
			items[i].name = name;
		}

		setWorkOrderData({...workOrderData, contractorForemen: items});
	};

	const onContractorForemenPhoneChange = (i: number, phone: string) => {
		const items = [...workOrderData.contractorForemen];
		if (items[i]) {
			items[i].phone = phone;
		}

		setWorkOrderData({...workOrderData, contractorForemen: items});
	};

	const onContractorForemenRemove = (i: number) => {
		const items = [...workOrderData.contractorForemen];
		items.splice(i, 1);
		setWorkOrderData({...workOrderData, contractorForemen: items});
	};

	const onPaymentMilestonesAdd = () => {
		const items = [...workOrderData.paymentMilestones];
		items.push({name: '', amount: 0, sequence: items.length + 1} as PaymentMilestone);
		setWorkOrderData({...workOrderData, paymentMilestones: items});
	};

	const onPaymentMilestonesNameChange = (i: number, name: string) => {
		const items = [...workOrderData.paymentMilestones];
		if (items[i]) {
			items[i].name = name;
		}

		setWorkOrderData({...workOrderData, paymentMilestones: items});
	};

	const onPaymentMilestonesAmountChange = (i: number, amount: number) => {
		const items = [...workOrderData.paymentMilestones];
		if (items[i]) {
			items[i].amount = amount;
		}

		setWorkOrderData({...workOrderData, paymentMilestones: items});
	};

	const onPaymentMilestonesSequenceChange = (i: number, sequence: number) => {
		const items = [...workOrderData.paymentMilestones];
		if (items[i]) {
			items[i].sequence = sequence;
		}

		setWorkOrderData({...workOrderData, paymentMilestones: items});
	};

	const onPaymentMilestonesRemove = (i: number) => {
		const items = [...workOrderData.paymentMilestones];
		items.splice(i, 1);
		setWorkOrderData({...workOrderData, paymentMilestones: items});
	};

	const onSubmitClicked = () => {
		const data: WorkOrder = {...workOrderData};

		if (!data.title) {
			void message.error('Please Enter Title');
			return;
		}

		if (!data.siteId) {
			void message.error('Please Select a Site');
			return;
		}

		if (!data.contractorId) {
			void message.error('Please Select a Contractor');
			return;
		}

		data.workOrderScheduleId = workOrderSchedule?.id ?? 0;

		checkFileErr(scopeOfWorkFileList);
		checkFileErr(materialScopeContractorSupContractorFileList);
		checkFileErr(materialScopeContractorProvPrithuFileList);
		checkFileErr(itemRateListExtraFileList);
		checkFileErr(qualityCheckFileList);
		checkFileErr(scheduleFileList);
		checkFileErr(contactPersonFileList);
		checkFileErr(generalClausesFileList);
		checkFileErr(drawingQuantityCalcFileList);
		checkFileErr(qualityAndPriceFileList);
		checkFileErr(tenderComparisonFileList);
		checkFileErr(otherFileList);

		data.scopeOfWorkAttachments = scopeOfWorkFileList.map((file: UploadFile) => ({
			id: 0,
			name: file.name,
			key: `${filePrefixKeys[file.uid]}/${file.name}`,
			parentType: AttachmentParentType.WORK_ORDER_SCOPE_OF_WORK,
		}));
		data.materialScopeContractorSupContractorAttachments = materialScopeContractorSupContractorFileList.map((file: UploadFile) => ({
			id: 0,
			name: file.name,
			key: `${filePrefixKeys[file.uid]}/${file.name}`,
			parentType: AttachmentParentType.WORK_ORDER_MATERIAL_CONTRACTOR,
		}));
		data.materialScopeContractorProvPrithuAttachments = materialScopeContractorProvPrithuFileList.map((file: UploadFile) => ({
			id: 0,
			name: file.name,
			key: `${filePrefixKeys[file.uid]}/${file.name}`,
			parentType: AttachmentParentType.WORK_ORDER_MATERIAL_PRITHU,
		}));
		data.itemRateListExtraAttachments = itemRateListExtraFileList.map((file: UploadFile) => ({
			id: 0,
			name: file.name,
			key: `${filePrefixKeys[file.uid]}/${file.name}`,
			parentType: AttachmentParentType.WORK_ORDER_ITEM_RATELIST,
		}));
		data.qualityCheckAttachments = qualityCheckFileList.map((file: UploadFile) => ({
			id: 0,
			name: file.name,
			key: `${filePrefixKeys[file.uid]}/${file.name}`,
			parentType: AttachmentParentType.WORK_ORDER_QUALITY_CHECK,
		}));
		data.scheduleAttachments = scheduleFileList.map((file: UploadFile) => ({
			id: 0,
			name: file.name,
			key: `${filePrefixKeys[file.uid]}/${file.name}`,
			parentType: AttachmentParentType.WORK_ORDER_SCHEDULE_ATTACHMENT,
		}));
		data.contactPersonAttachments = contactPersonFileList.map((file: UploadFile) => ({
			id: 0,
			name: file.name,
			key: `${filePrefixKeys[file.uid]}/${file.name}`,
			parentType: AttachmentParentType.WORK_ORDER_CONTACT_PERSON,
		}));
		data.generalClausesAttachments = generalClausesFileList.map((file: UploadFile) => ({
			id: 0,
			name: file.name,
			key: `${filePrefixKeys[file.uid]}/${file.name}`,
			parentType: AttachmentParentType.WORK_ORDER_GENERAL_CLAUSES,
		}));
		data.drawingQuantityCalcAttachments = drawingQuantityCalcFileList.map((file: UploadFile) => ({
			id: 0,
			name: file.name,
			key: `${filePrefixKeys[file.uid]}/${file.name}`,
			parentType: AttachmentParentType.WORK_ORDER_DRAWING_CALCULATION,
		}));
		data.qualityAndPriceAttachments = qualityAndPriceFileList.map((file: UploadFile) => ({
			id: 0,
			name: file.name,
			key: `${filePrefixKeys[file.uid]}/${file.name}`,
			parentType: AttachmentParentType.WORK_ORDER_QUALITY_PRICE,
		}));
		data.tenderComparisonAttachments = tenderComparisonFileList.map((file: UploadFile) => ({
			id: 0,
			name: file.name,
			key: `${filePrefixKeys[file.uid]}/${file.name}`,
			parentType: AttachmentParentType.WORK_ORDER_TENDER_COMPARISON,
		}));
		data.otherFilesAttachments = otherFileList.map((file: UploadFile) => ({
			id: 0,
			name: file.name,
			key: `${filePrefixKeys[file.uid]}/${file.name}`,
			parentType: AttachmentParentType.WORK_ORDER_OTHER_FILES,
		}));

		dispatch(create(data));
	};

	const masterOptions = masterAllIds.map(id => ({value: id.toString(), label: masterByIds[id]?.processName}));
	const siteOptions: FilterOptionItem[] = sites.map(site => ({label: site.name, value: String(site.id)}));
	const layout = {
		labelCol: {span: 8},
		wrapperCol: {span: 20},
	};
	const tailLayout = {
		wrapperCol: {offset: 8, span: 20},
	};

	return (
		<DefaultLayout currentPath={uiPaths.createWorkOrderFromSchedule}>
			<Spin
				size='large'
				spinning={loading}
				tip={'Loading...'}
			>
				<Row className='mb-15'>
					<Col span={24}>
						<Typography.Title level={2} style={{textAlign: 'center'}}>Work Order</Typography.Title>
						<Button onClick={() => {
							history.goBack();
						}}>Back</Button>
						<Form
							{...layout}
							name='basic'
						>
							<Form.Item {...tailLayout}>
								<Radio.Group
									onChange={(e: any) => {
										setWorkOrderData({...workOrderData, orderType: String(e.target.value) as OrderType});
									}}
									value={workOrderData.orderType}
								>
									<Radio value={OrderType.WORK}>Work Order</Radio>
									<Radio value={OrderType.RATE}>Rate Order</Radio>
								</Radio.Group>
							</Form.Item>
							<Form.Item label='Title of Work Order'>
								<Input
									placeholder={'Enter Title'}
									id={'WorkOrderTitle'}
									defaultValue={workOrderData.title ?? ''}
									value={workOrderData.title ?? ''}
									onChange={(e: any) => {
										setWorkOrderData({...workOrderData, title: String(e.target.value)});
									}}
								/>
							</Form.Item>
							<Form.Item label='Site'>
								{workOrderSchedule?.siteId
									&& workOrderSchedule.site && workOrderData.siteId ? workOrderSchedule.site.name : (
										<SiteSelect
											siteOptions={siteOptions}
											style={{width: 250}}
											placeholder='Select a Site'
											onChange={value => {
												setWorkOrderData({...workOrderData, siteId: parseNum(String(value))});
											}}
										/>
									)}
							</Form.Item>
							<Form.Item label='Work Type'>
								{workOrderSchedule?.projectProcessMasterId
									&& workOrderSchedule.projectProcessMaster && workOrderData.projectProcessMasterId
									? workOrderSchedule.projectProcessMaster.processName : (
										<Select
											showSearch={true}
											style={{width: 250}}
											placeholder='Select a Type'
											optionFilterProp='children'
											onChange={value => {
												setWorkOrderData({...workOrderData, projectProcessMasterId: parseNum(value as string)});
											}}
											// C filterOption={(input, option) => String(option?.label).includes(input)}
										>
											{masterOptions.map((option: FilterOptionItem, ix: number) =>
												<Option key={ix} value={option.value}>{option.label}</Option>,
											)}
										</Select>
									)}
							</Form.Item>
							<Form.Item label='Contractor'>
								<Select
									id={'SelectContractor'}
									showSearch={true}
									style={{width: 250}}
									placeholder='Select a Contractor'
									optionFilterProp='children'
									onChange={value => {
										setWorkOrderData({...workOrderData, contractorId: parseNum(value as string)});
									}}
									// C filterOption={(input, option) => String(option?.label).includes(input)}
								>
									{contractorOptions?.map((option: FilterOptionItem, ix: number) =>
										<Option key={ix} value={option.value}>{option.label}</Option>,
									)}
								</Select>
							</Form.Item>

							<Form.Item label='How often contractor will visit the site'>
								<Input
									placeholder={''}
									style={{width: 250}}
									defaultValue={workOrderData.contractorVisit ?? ''}
									value={workOrderData.contractorVisit ?? ''}
									onChange={(e: any) => {
										setWorkOrderData({...workOrderData, contractorVisit: String(e.target.value)});
									}}
								/>
							</Form.Item>

							<Form.Item
								label='Work Order Quantity'
								extra={`Total Work Order Quantity: ${workOrderSchedule?.budgetQuantity ?? 'N/A'} ${workOrderSchedule?.budgetUnit ?? ''}`}
							>
								<Input
									type={'number'}
									placeholder={''}
									addonBefore={`Budgeted Quantity ${workOrderSchedule?.budgetQuantity ?? 'N/A'}`}
									addonAfter={`${workOrderSchedule?.budgetUnit ?? 'N/A'}`}
									defaultValue={workOrderData.workOrderQuantity}
									value={workOrderData.workOrderQuantity}
									onChange={(e: any) => {
										setWorkOrderData({...workOrderData, workOrderQuantity: parseNum(e.target.value as string)});
									}}
								/>
							</Form.Item>

							<Form.Item label={'Billed to Client'}>
								<Switch
									checked={workOrderData.billedToClient}
									onChange={checked => {
										setWorkOrderData({...workOrderData, ...{billedToClient: checked}});
									}}
								/>
							</Form.Item>

							<Form.Item label='Material Query Category'>
								<Select
									showSearch={true}
									style={{width: 250}}
									placeholder='Select a Category'
									optionFilterProp='children'
									onChange={value => {
										setCategoryId(parseNum(String(value)));
									}}
								>
									{categoryOptions.map((option: FilterOptionItem, ix: number) => (
										<Select.Option key={ix} value={option.value}>
											{option.label}
										</Select.Option>
									))}
								</Select>
							</Form.Item>

							{categoryId ? (
								<Form.Item label='Material Query Sub Category'>
									<Select
										showSearch={true}
										style={{width: 250}}
										placeholder='Select a Sub-Category'
										optionFilterProp='children'
										onChange={value => {
											const id = parseNum(String(value));
											setWorkOrderData({...workOrderData, ...{subCategoryId: id}});
										}}
									>
										{subCategoryOptions(categoryId).map((option: FilterOptionItem, ix: number) => (
											<Select.Option key={ix} value={option.value}>
												{option.label}
											</Select.Option>
										))}
									</Select>
								</Form.Item>
							) : []}

							<Form.Item label="Contractor's foremen/supervisors on site">
								{workOrderData.contractorForemen.map((item, i) => (
									<Space key={i} style={{width: '100%'}}>
										<Form.Item
											rules={[{required: true, message: 'Missing name'}]}
										>
											<Input
												placeholder='Foreman/Supervisor Name'
												value={item.name}
												defaultValue={item.name}
												onChange={e => {
													onContractorForemenNameChange(i, e.target.value);
												}}
											/>
										</Form.Item>
										<Form.Item
											rules={[{required: true, message: 'Missing contact number'}]}
										>
											<Input
												placeholder='Phone Number'
												value={item.phone}
												defaultValue={item.phone}
												onChange={e => {
													onContractorForemenPhoneChange(i, e.target.value);
												}}
											/>
										</Form.Item>
										<Form.Item>
											<MinusCircleOutlined onClick={() => {
												onContractorForemenRemove(i);
											}} />
										</Form.Item>
									</Space>
								))}
								<Form.Item>
									<Button type='dashed' onClick={() => {
										onContractorForemenAdd();
									}} block={true} icon={<PlusOutlined />}>
										Add Contractor Foremen/Supervisors
									</Button>
								</Form.Item>
							</Form.Item>

							<Form.Item label='Payment Milestones'>
								{workOrderData.paymentMilestones.map((item, i) => (
									<Space key={i} style={{width: '100%'}}>
										<Form.Item
											rules={[{required: true, message: 'Missing contact number'}]}
										>
											<Input
												type={'number'}
												style={{width: 60}}
												value={item.sequence}
												defaultValue={item.sequence}
												onChange={e => {
													onPaymentMilestonesSequenceChange(i, parseInt(e.target.value, 10));
												}}
											/>
										</Form.Item>
										<Form.Item
											rules={[{required: true, message: 'Missing name'}]}
										>
											<Input
												placeholder='Milestone Name'
												style={{width: 400}}
												value={item.name}
												defaultValue={item.name}
												onChange={e => {
													onPaymentMilestonesNameChange(i, e.target.value);
												}}
											/>
										</Form.Item>
										<Form.Item
											rules={[{required: true, message: 'Missing Milestone Amount'}]}
										>
											<Input
												type={'number'}
												style={{width: 140}}
												placeholder='Milestone Amount'
												value={item.amount}
												defaultValue={item.amount}
												onChange={e => {
													onPaymentMilestonesAmountChange(i, parseInt(e.target.value, 10));
												}}
											/>
										</Form.Item>
										<Form.Item>
											<MinusCircleOutlined onClick={() => {
												onPaymentMilestonesRemove(i);
											}} />
										</Form.Item>
									</Space>
								))}
								<Form.Item>
									<Button type='dashed' onClick={() => {
										onPaymentMilestonesAdd();
									}} block={true} icon={<PlusOutlined />}>
										Add Payment Milestones
									</Button>
								</Form.Item>
							</Form.Item>

							<Form.Item label='Scope of Work'>
								<TextArea
									placeholder={'Enter Scope of Work'}
									id={'ScopeOfWork'}
									defaultValue={workOrderData.scopeOfWork ?? ''}
									value={workOrderData.scopeOfWork ?? ''}
									onChange={(e: any) => {
										setWorkOrderData({...workOrderData, scopeOfWork: String(e.target.value)});
									}}
								/>
								<br /><br />
								<FileUpload
									label={'Click to Upload Scope of Work Files'}
									prefix={'work-order'}
									fileList={scopeOfWorkFileList}
									filePrefixKeys={filePrefixKeys}
									onFileChange={(scopeOfWorkFileList, filePrefixKeys) => {
										setScopeOfWorkFileList(scopeOfWorkFileList);
										setFilePrefixKeys(filePrefixKeys);
									}}
								/>
							</Form.Item>

							<Form.Item label='Material Scope of contractor & Supplied by the Contractor'>
								<TextArea
									placeholder={'Enter Material Scope of contractor & Supplied by the Contractor'}
									id={'MaterialContractor'}
									defaultValue={workOrderData.materialScopeContractorSupContractor ?? ''}
									value={workOrderData.materialScopeContractorSupContractor ?? ''}
									onChange={(e: any) => {
										setWorkOrderData({...workOrderData, materialScopeContractorSupContractor: String(e.target.value)});
									}}
								/>
								<br /><br />
								<FileUpload
									label={'Click to Upload Material Scope of contractor & Supplied by the Contractor Files'}
									prefix={'work-order'}
									fileList={materialScopeContractorSupContractorFileList}
									filePrefixKeys={filePrefixKeys}
									onFileChange={(materialScopeContractorSupContractorFileList, filePrefixKeys) => {
										setMaterialScopeContractorSupContractorFileList(materialScopeContractorSupContractorFileList);
										setFilePrefixKeys(filePrefixKeys);
									}}
								/>
							</Form.Item>

							<Form.Item label='Material in Scope of contractor & provided by Prithu'>
								<TextArea
									placeholder={'Enter Material in Scope of contractor & provided by Prithu'}
									id={'MaterialPrithu'}
									defaultValue={workOrderData.materialScopeContractorProvPrithu ?? ''}
									value={workOrderData.materialScopeContractorProvPrithu ?? ''}
									onChange={(e: any) => {
										setWorkOrderData({...workOrderData, materialScopeContractorProvPrithu: String(e.target.value)});
									}}
								/>
								<br /><br />
								<FileUpload
									label={'Click to Upload Material in Scope of contractor & provided by Prithu Files'}
									prefix={'work-order'}
									fileList={materialScopeContractorProvPrithuFileList}
									filePrefixKeys={filePrefixKeys}
									onFileChange={(materialScopeContractorProvPrithuFileList, filePrefixKeys) => {
										setMaterialScopeContractorProvPrithuFileList(materialScopeContractorProvPrithuFileList);
										setFilePrefixKeys(filePrefixKeys);
									}}
								/>
							</Form.Item>

							<Form.Item label='Item rate list for extra work and rework'>
								<TextArea
									placeholder={'Enter Item rate list for extra work and rework'}
									id={'ItemRateList'}
									defaultValue={workOrderData.itemRateListExtra ?? ''}
									value={workOrderData.itemRateListExtra ?? ''}
									onChange={(e: any) => {
										setWorkOrderData({...workOrderData, itemRateListExtra: String(e.target.value)});
									}}
								/>
								<br /><br />
								<FileUpload
									label={'Click to Upload Item rate list for extra work and rework Files'}
									prefix={'work-order'}
									fileList={itemRateListExtraFileList}
									filePrefixKeys={filePrefixKeys}
									onFileChange={(itemRateListExtraFileList, filePrefixKeys) => {
										setItemRateListExtraFileList(itemRateListExtraFileList);
										setFilePrefixKeys(filePrefixKeys);
									}}
								/>
							</Form.Item>

							<Form.Item label='Quality Defect'>
								<TextArea
									placeholder={'Enter Quality Defect'}
									id={'QualityDefect'}
									defaultValue={workOrderData.qualityCheck ?? ''}
									value={workOrderData.qualityCheck ?? ''}
									onChange={(e: any) => {
										setWorkOrderData({...workOrderData, qualityCheck: String(e.target.value)});
									}}
								/>
								<br /><br />
								<FileUpload
									label={'Click to Upload Quality Defect Files'}
									prefix={'work-order'}
									fileList={qualityCheckFileList}
									filePrefixKeys={filePrefixKeys}
									onFileChange={(qualityCheckFileList, filePrefixKeys) => {
										setQualityCheckFileList(qualityCheckFileList);
										setFilePrefixKeys(filePrefixKeys);
									}}
								/>
							</Form.Item>

							<Form.Item label='Project Schedule and Delay Penalty calculation'>
								<TextArea
									placeholder={'Enter Project Schedule and Delay Penalty calculation'}
									id={'ProjectSchedule'}
									defaultValue={workOrderData.schedule ?? ''}
									value={workOrderData.schedule ?? ''}
									onChange={(e: any) => {
										setWorkOrderData({...workOrderData, schedule: String(e.target.value)});
									}}
								/>
								<br /><br />
								<FileUpload
									label={'Click to Upload Project Schedule and Delay Penalty calculation Files'}
									prefix={'work-order'}
									fileList={scheduleFileList}
									filePrefixKeys={filePrefixKeys}
									onFileChange={(scheduleFileList, filePrefixKeys) => {
										setScheduleFileList(scheduleFileList);
										setFilePrefixKeys(filePrefixKeys);
									}}
								/>
							</Form.Item>

							<Form.Item label='Contact Person'>
								<TextArea
									placeholder={'Enter Contact Person'}
									id={'ContactPerson'}
									defaultValue={workOrderData.contactPerson ?? ''}
									value={workOrderData.contactPerson ?? ''}
									onChange={(e: any) => {
										setWorkOrderData({...workOrderData, contactPerson: String(e.target.value)});
									}}
								/>
								<br /><br />
								<FileUpload
									label={'Click to Upload Contact Person Files'}
									prefix={'work-order'}
									fileList={contactPersonFileList}
									filePrefixKeys={filePrefixKeys}
									onFileChange={(contactPersonFileList, filePrefixKeys) => {
										setContactPersonFileList(contactPersonFileList);
										setFilePrefixKeys(filePrefixKeys);
									}}
								/>
							</Form.Item>

							<Form.Item label='Term of agreement'>
								<TextArea
									placeholder={'Enter Term of agreement'}
									id={'TermOfAgreement'}
									defaultValue={workOrderData.generalClauses ?? ''}
									value={workOrderData.generalClauses ?? ''}
									onChange={(e: any) => {
										setWorkOrderData({...workOrderData, generalClauses: String(e.target.value)});
									}}
								/>
								<br /><br />
								<FileUpload
									label={'Click to Upload Term of agreement Files'}
									prefix={'work-order'}
									fileList={generalClausesFileList}
									filePrefixKeys={filePrefixKeys}
									onFileChange={(generalClausesFileList, filePrefixKeys) => {
										setGeneralClausesFileList(generalClausesFileList);
										setFilePrefixKeys(filePrefixKeys);
									}}
								/>
							</Form.Item>

							<Form.Item label='Drawing files'>
								<TextArea
									placeholder={'Enter Drawing files'}
									id={'DrawingFiles'}
									defaultValue={workOrderData.drawingQuantityCalc ?? ''}
									value={workOrderData.drawingQuantityCalc ?? ''}
									onChange={(e: any) => {
										setWorkOrderData({...workOrderData, drawingQuantityCalc: String(e.target.value)});
									}}
								/>
								<br /><br />
								<FileUpload
									label={'Click to Upload Drawing Files'}
									prefix={'work-order'}
									fileList={drawingQuantityCalcFileList}
									filePrefixKeys={filePrefixKeys}
									onFileChange={(drawingQuantityCalcFileList, filePrefixKeys) => {
										setDrawingQuantityCalcFileList(drawingQuantityCalcFileList);
										setFilePrefixKeys(filePrefixKeys);
									}}
								/>
							</Form.Item>

							<Form.Item label='Quantity calculation'>
								<TextArea
									placeholder={'Enter Quantity calculation'}
									id={'QuantityCalculation'}
									defaultValue={workOrderData.qualityAndPrice ?? ''}
									value={workOrderData.qualityAndPrice ?? ''}
									onChange={(e: any) => {
										setWorkOrderData({...workOrderData, qualityAndPrice: String(e.target.value)});
									}}
								/>
								<br /><br />
								<FileUpload
									label={'Click to Upload Quantity calculation Files'}
									prefix={'work-order'}
									fileList={qualityAndPriceFileList}
									filePrefixKeys={filePrefixKeys}
									onFileChange={(qualityAndPriceFileList, filePrefixKeys) => {
										setQualityAndPriceFileList(qualityAndPriceFileList);
										setFilePrefixKeys(filePrefixKeys);
									}}
								/>
							</Form.Item>

							<Form.Item label='Tender Comparison Sheet'>
								<TextArea
									placeholder={'Enter Tender Comparison Sheet'}
									id={'TenderComparisonSheet'}
									defaultValue={workOrderData.tenderComparison ?? ''}
									value={workOrderData.tenderComparison ?? ''}
									onChange={(e: any) => {
										setWorkOrderData({...workOrderData, tenderComparison: String(e.target.value)});
									}}
								/>
								<br /><br />
								<FileUpload
									label={'Click to Upload Tender Comparison Sheet Files'}
									prefix={'work-order'}
									fileList={tenderComparisonFileList}
									filePrefixKeys={filePrefixKeys}
									onFileChange={(tenderComparisonFileList, filePrefixKeys) => {
										setTenderComparisonFileList(tenderComparisonFileList);
										setFilePrefixKeys(filePrefixKeys);
									}}
								/>
							</Form.Item>

							<Form.Item label='Others Files'>
								<FileUpload
									prefix={'work-order'}
									fileList={otherFileList}
									filePrefixKeys={filePrefixKeys}
									onFileChange={(otherFileList, filePrefixKeys) => {
										setOtherFileList(otherFileList);
										setFilePrefixKeys(filePrefixKeys);
									}}
								/>
							</Form.Item>

							{workOrderData.orderType === OrderType.RATE && (
								<Form.Item label='Quantity Measure Rule'>
									<TextArea
										placeholder={'Enter Quantity Measure Rule'}
										defaultValue={workOrderData.quantityMeasureRule ?? ''}
										value={workOrderData.quantityMeasureRule ?? ''}
										onChange={(e: any) => {
											setWorkOrderData({...workOrderData, quantityMeasureRule: String(e.target.value)});
										}}
									/>
								</Form.Item>
							)}

							{workOrderData.orderType === OrderType.RATE && (
								<Form.Item label='Rate Per Unit'>
									<Input
										placeholder={''}
										style={{width: 250}}
										defaultValue={workOrderData.ratePerUnit}
										value={workOrderData.ratePerUnit}
										onChange={(e: any) => {
											setWorkOrderData({...workOrderData, ratePerUnit: String(e.target.value)});
										}}
									/>
								</Form.Item>
							)}

							<Form.Item {...tailLayout}>
								<Button type='primary' id={'Submit'} onClick={onSubmitClicked}>
									Submit
								</Button>
							</Form.Item>
						</Form>
					</Col>
				</Row>
			</Spin>
		</DefaultLayout>
	);
};
