import {Button, Card, Input, Select, Space} from 'antd';
import React, {useState} from 'react';
import {parseNum} from 'app/helpers';
import {type Category} from '../Category/category';
import {type FilterOptionItem} from 'app/models/ui-filter';

type Props = {
	canEdit: boolean;
	categories: Category[];
	currentValue?: Category;
	onSubmit: (value: number) => void;
};

export const EditMaterialQueryCategory: React.FC<Props> = ({canEdit, categories, currentValue, onSubmit}) => {
	const [isEditing, setIsEditing] = useState(false);
	const [categoryId, setCategoryId] = useState<number>(0);
	const [subCategoryId, setSubCategoryId] = useState<number>(0);

	const categoryOptions = categories
		.filter(category => !category.parentId)
		.map(item => ({value: item.id.toString(), label: item.name}));

	const subCategoryOptions = parentId => categories
		.filter(category => category.parentId === parentId)
		.map(item => ({value: item.id.toString(), label: item.name}));

	const handleSubmit = () => {
		onSubmit(subCategoryId);
		setIsEditing(false);
	};

	return (
		isEditing ? (
			<Card>
				<Space>
					<Select
						showSearch={true}
						style={{width: 250}}
						placeholder='Select a Category'
						optionFilterProp='children'
						onChange={value => {
							setCategoryId(parseNum(String(value)));
						}}
					>
						{categoryOptions.map((option: FilterOptionItem, ix: number) => (
							<Select.Option key={ix} value={option.value}>
								{option.label}
							</Select.Option>
						))}
					</Select>

					{categoryId ? (
						<Select
							showSearch={true}
							style={{width: 250}}
							placeholder='Select a Sub-Category'
							optionFilterProp='children'
							onChange={value => {
								const id = parseNum(String(value));
								setSubCategoryId(id);
							}}
						>
							{subCategoryOptions(categoryId).map((option: FilterOptionItem, ix: number) => (
								<Select.Option key={ix} value={option.value}>
									{option.label}
								</Select.Option>
							))}
						</Select>
					) : []}
					<Button type='primary' onClick={handleSubmit}>Save</Button>
					<Button onClick={() => {
						setIsEditing(false);
					}}>Cancel</Button>
				</Space>
			</Card>
		) : (
			<Space>
				<label>{currentValue?.name}</label>
				{canEdit ? (
					<Button type='primary' style={{margin: '10px'}} onClick={() => {
						setIsEditing(true);
					}}>Edit</Button>
				) : []}
			</Space>
		)
	);
};
