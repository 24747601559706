import React, {useEffect, useState} from 'react';
import {checkFileErr, getPermissionSites, parseNum} from 'app/helpers';
import {
	Button,
	Col,
	DatePicker,
	Form,
	Input,
	message,
	Row,
	Select,
	Spin,
	Switch,
} from 'antd';
import {create, initPage} from './actions';
import {useDispatch, useSelector} from 'react-redux';
import {type UploadFile} from 'app/models/upload-file';
import {FileUpload} from '../Common/FileUpload';
import {type FilterOptionItem} from 'app/models/ui-filter';
import {
	ModuleName,
	ModulePermission,
	type Site,
	WorkOrderStatus,
	type AttachmentData,
} from 'app/models';
import {get as getWorkOrders} from 'app/components/WorkOrder/actions';
import type Types from 'MyTypes';
import {materialQueryLeadTimeSheetLink, siteResourceDirectorySheetLink} from 'app/constants';
import {get as getCategories} from '../Category/actions';
import {CategoryType} from '../Category/category';
import dayjs from 'dayjs';

export const ViewMaterialQueryForm: React.FC = () => {
	const dispatch = useDispatch();

	const {byIds: byIdsWo, allIds: allIdsWo} = useSelector(
		(state: Types.RootState) => state.workOrder,
	);
	const {byModule: permissions, allSites} = useSelector(
		(state: Types.RootState) => state.userPermission,
	);
	const {loading, dataUpdated, errorMessage} = useSelector(
		(state: Types.RootState) => state.materialQuery,
	);
	const {byIds: categoryByIds, allIds: allCategoryIds} = useSelector((state: Types.RootState) => state.category);

	const sites: Site[] = getPermissionSites(
		permissions,
		ModuleName.MATERIAL_QUERY,
		ModulePermission.WRITE,
		allSites,
	);

	const categories = allCategoryIds.map(id => categoryByIds[id]).filter(category => !category.isDeactivated);

	const categoryOptions = categories
		.filter(category => !category.parentId)
		.map(item => ({value: item.id.toString(), label: item.name}));

	const subCategoryOptions = parentId => categories
		.filter(category => category.parentId === parentId)
		.map(item => ({value: item.id.toString(), label: item.name}));

	const [description, setDescription] = useState<string>('');
	// C const [materialType, setMaterialType] = useState<string>('');
	const [siteId, setSiteId] = useState<number>(0);
	const [categoryId, setCategoryId] = useState<number>(0);
	const [subCategoryId, setSubCategoryId] = useState<number>(0);
	const [debitedToContractor, setDebitedToContractor]
    = useState<boolean>(false);
	const [debitWorkOrderId, setDebitWorkOrderId] = useState<number>(0);
	const [requirementDate, setRequirementDate] = React.useState<string>('');
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [filePrefixKeys, setFilePrefixKeys] = useState<Record<string, string>>(
		{},
	);

	useEffect(() => {
		dispatch(initPage());
		dispatch(getCategories({
			where: {
				categoryType: CategoryType.MATERIAL_QUERY,
			},
		}));
		dispatch(
			getWorkOrders({
				where: {siteId: {inq: sites.map((site: Site) => site.id)}},
			}),
		);
	}, []);

	useEffect(() => {
		if (dataUpdated) {
			void message.success('Created successfully');
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}
	}, [dataUpdated]);

	useEffect(() => {
		if (errorMessage) {
			void message.error(errorMessage);
		}
	}, [errorMessage]);

	const onSubmitClicked = () => {
		if (!siteId) {
			void message.error('Please Select a site');
			return;
		}

		if (!description) {
			void message.error('Please Enter Query Point');
			return;
		}

		if (debitedToContractor && !debitWorkOrderId) {
			void message.error('Please select a work order to debit from!');
			return;
		}

		if (!categoryId) {
			void message.error('Please select a category!');
			return;
		}

		if (!subCategoryId) {
			void message.error('Please select a sub category!');
			return;
		}

		checkFileErr(fileList);

		const attachments = fileList.map(
			(file: UploadFile) => {
				const x: AttachmentData = {
					name: file.name,
					key: `${filePrefixKeys[file.uid]}/${file.name}`,
				};
				return x;
			},
		);

		const data = {
			siteId,
			categoryId,
			subCategoryId,
			description,
			debitedToContractor,
			debitWorkOrderId,
			requirementDate: requirementDate ? dayjs(requirementDate).format('YYYY-MM-DDTHH:mm:ss.sssZ') : undefined,
			attachments,
		};

		dispatch(create(data));
	};

	const layout = {
		labelCol: {span: 8},
		wrapperCol: {span: 16},
	};
	const tailLayout = {
		wrapperCol: {offset: 8, span: 16},
	};

	return (
		<Spin size='large' spinning={loading} tip={'Loading...'}>
			<Row className='mb-15'>
				<Col span={24}>
					<Form {...layout}>
						<Form.Item label='Site'>
							<Select
								showSearch={true}
								style={{width: 250}}
								placeholder='Select a Site'
								optionFilterProp='children'
								onChange={value => {
									setSiteId(parseNum(String(value)));
								}}
							>
								{sites.map((site: Site, ix: number) => (
									<Select.Option key={ix} value={site.id}>
										{site.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label='Category*'>
							<Select
								showSearch={true}
								style={{width: 250}}
								placeholder='Select a Category'
								optionFilterProp='children'
								onChange={value => {
									setCategoryId(parseNum(String(value)));
								}}
							>
								{categoryOptions.map((option: FilterOptionItem, ix: number) => (
									<Select.Option key={ix} value={option.value}>
										{option.label}
									</Select.Option>
								))}
							</Select>
						</Form.Item>

						{categoryId ? (
							<Form.Item label='Sub Category*'>
								<Select
									showSearch={true}
									style={{width: 250}}
									placeholder='Select a Sub-Category'
									optionFilterProp='children'
									onChange={value => {
										const id = parseNum(String(value));
										setSubCategoryId(id);
										setDebitedToContractor(categoryByIds[id]?.isDebitable ?? false);
										setDebitWorkOrderId(0);
									}}
								>
									{subCategoryOptions(categoryId).map((option: FilterOptionItem, ix: number) => (
										<Select.Option key={ix} value={option.value}>
											{option.label}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						) : []}

						<Form.Item label='Lead Time Sheet Link' name='leadTimeSheetLink'>
							<a
								href={materialQueryLeadTimeSheetLink}
								target='_blank'
								rel='noreferrer'
							>
								{materialQueryLeadTimeSheetLink}
							</a>
						</Form.Item>

						<Form.Item label='Site Resources Directory' name='siteResourceDirectorySheetLink'>
							<a
								href={siteResourceDirectorySheetLink}
								target='_blank'
								rel='noreferrer'
							>
								{siteResourceDirectorySheetLink}
							</a>
						</Form.Item>

						<Form.Item label='Query Point'>
							<Input.TextArea
								rows={3}
								placeholder={'Enter Query Point'}
								defaultValue={description ?? ''}
								value={description ?? ''}
								onChange={(e: any) => {
									setDescription(String(e.target.value));
								}}
							/>
						</Form.Item>

						<Form.Item label='Material Requirement Date'>
							<DatePicker
								onChange={(_, dateString) => {
									setRequirementDate(dateString);
								}}
							/>
						</Form.Item>

						<Form.Item label='Attachments'>
							<FileUpload
								prefix={'material-query'}
								fileList={fileList}
								filePrefixKeys={filePrefixKeys}
								onFileChange={(fileList, filePrefixKeys) => {
									setFileList(fileList);
									setFilePrefixKeys(filePrefixKeys);
								}}
							/>
						</Form.Item>

						<Form.Item label='Debit this material query to a contractor?'>
							<Switch
								checked={debitedToContractor}
								onChange={checked => {
									setDebitedToContractor(checked);
									setDebitWorkOrderId(0);
								}}
							/>
						</Form.Item>

						{debitedToContractor ? (
							<>
								<Form.Item label='Work Order of Contractor'>
									<Select
										showSearch={true}
										style={{width: 250}}
										placeholder='Select a Work Order'
										optionFilterProp='children'
										onChange={value => {
											setDebitWorkOrderId(parseNum(String(value)));
										}}
									>
										{allIdsWo
											.filter(
												(id: number) =>
													(!siteId || byIdsWo[id].siteId === siteId)
                          && byIdsWo[id].status === WorkOrderStatus.PUBLISHED && byIdsWo[id].subCategoryId === subCategoryId,
											)
											.map((id: number) => ({
												value: id.toString(),
												label: byIdsWo[id].title,
											}))
											.map((option: FilterOptionItem, ix: number) => (
												<Select.Option key={ix} value={option.value}>
													{option.label}
												</Select.Option>
											))}
									</Select>
								</Form.Item>
							</>
						) : (
							[]
						)}

						<Form.Item {...tailLayout}>
							<Button type='primary' onClick={onSubmitClicked}>
                Submit
							</Button>
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</Spin>
	);
};
